export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setData(state, data) {
    let listDevice = [];
    let listPartner = [];
    let listMonth = [];
    Object.values(data).map((e) => {
      listDevice.push(e.device);
      listPartner.push(e.partner);
      listMonth.push(e.month);
      state.listMonth = listMonth;
      state.listDevice = listDevice;
      state.listPartner = listPartner;
    });
  },
};
