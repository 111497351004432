import { FORM_MODE,TYPESTAFF } from '@/util/common-constant';
import {STATUS_ACTION} from "@/config/Constant";

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.role = {
      name: '',
      status: STATUS_ACTION.ACTIVE,
      roleMenus: [],
      staff:TYPESTAFF.N
    };
    state.error = false;
    state.listUsersRoleId = []
    state.totalUserRoles = 0,
    state.isTableSelected = false
  },
  setFormValue(state, { role, formMode,listUserInRole,totalUserRoles }) {
    state.visible = true;
    state.formMode = formMode;
    state.role = {
      ...role,
    };
    state.error = false;
    state.listUsersRoleId = []
    state.totalUserRoles = 0,
    state.isTableSelected = false,
    state.listUsersRoleId = listUserInRole;
    state.totalUserRoles = totalUserRoles;
  },
  getAllSuccess(state, data) {
    state.list = data;
  },
};
