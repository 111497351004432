<template>
  <div>
    <div class="img-notification-modal mx-auto w-4/5 2xl:w-full h-[350px] 2xl:h-[480px]">
      <Image
          :src="detail.imageUrl"
          :alt="detail.imageUrl"
          class="w-full h-full shrink-0 rounded-[4px]"
      >
        <template #previewMask>
          <div>Preview</div>
        </template>
      </Image>
    </div>
    <div class="font-bold text-[16px] mt-[14px] mb-[14px] 2xl:mb-[24px]">
      {{
        detail.lastName && detail.firstName
            ? getNameProfile(detail)
            : t('notification.undefined')
      }}
    </div>
    <div class="grid grid-cols-3 gap-x-[24px] gap-y-[16px] 2xl:gap-x-[32px] 2xl:gap-y-[24px]">
      <cds-input-field
          class="input-field-inout"
          :label="t('event.classify')"
          :value="
                detail.profileType ? t(PROFILE_TYPE_COLOR[detail.profileType].text) : ''
              "
      />
      <cds-input-field
          class="input-field-inout"
          :label="t('event.unit')"
          :value="
                detail.unitName ? detail.unitName : ''
              "
      />
      <cds-input-field
          class="input-field-inout"
          :label="t('event.position')"
          :value="
                detail.occupation ? detail.occupation : ''
              "
      />
      <cds-input-field
          class="input-field-inout"
          :label="t('event.gender')"
          :value="
                detail.gender ? t(GENDER[detail.gender]) : ''
              "
      />
      <cds-input-field
          class="input-field-inout"
          :label="t('event.createdAt')"
          :value="
                detail.createdAt ? formatDate(detail.createdAt) : ''
              "
      />
      <cds-input-field
          class="input-field-inout"
          :label="t('event.status')"
          :value="
                 EVENT_STATUS[detail.eventType]
                          ? t(EVENT_STATUS[detail.eventType].text)
                          : ''
              "
      />
      <cds-input-field
          class="input-field-inout"
          :label="t('event.type')"
          :value="
                EVENT_TYPE[detail.warningType]
                          ? t(EVENT_TYPE[detail.warningType].text)
                          : ''
              "
      />
      <cds-input-field
          class="input-field-inout"
          :label="t('event.camera')"
          :value="
                detail.cameraName ? detail.cameraName : ''
              "
      />
    </div>
  </div>
</template>
<script setup>
import {useI18n} from "vue-i18n";
import {EVENT_STATUS, EVENT_TYPE, GENDER, PROFILE_TYPE_COLOR} from "@/util/common-constant";
import {formatDate, getNameProfile} from "@/util/common-utils";
import {Image} from "ant-design-vue";

const { t } = useI18n()
const props = defineProps({
  detail: {
    type: Object,
    default: {}
  }
})
</script>
<style>
img.ant-image-preview-img {
  display: inline-block;
  z-index: 1200 !important;
}
.img-notification-modal {
  img.ant-image-img {
    height: 100%;
  }
  .ant-image {
    width: 100%;
    height: 100%;
  }
}
</style>
