import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
// import { notification } from 'ant-design-vue';
// import { i18n } from '@/main';

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.REGISTRATION_REPORT,
  listMost: [],
  listTotal: {
    total: 0,
    complete: 0,
    overTime: 0,
    noCheckIn: 0,
  },
  dataLineChart: {
    xAxis: [],
    series: []
  }
});

const actions = {
  async getChart({ commit, state }, params) {
    commit('setLoading', true)
    const response = await DataService.callApi(state.api.STATISTIC_REGISTER_IN_OUT, null, params);
    checkResponse(response, () => {
      state.listTotal.total = response.data.total;
      state.listTotal.complete = response.data.complete;
      state.listTotal.overTime = response.data.overTime;
      state.listTotal.noCheckIn = response.data.noCheckIn;
      commit('setLineChart', response.data);
    });
    commit('setLoading', false)
  },
  async getTable({ commit, state }) {
    commit('setLoading', true)
    const response = await DataService.callApi(state.api.MOST_REGISTER_IN_OUT);
    checkResponse(response, () => {
      state.listMost = response.data;
    });
    commit('setLoading', false)
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
