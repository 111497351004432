<template>
  <template v-if="readOnly">
    {{ valueInput }}
  </template>
  <a-input
    v-else
    class="w-100 ant-input-sm"
    v-model:value="valueInput"
    :maxlength="maxlength"
    :show-count="showCount"
    :size="size"
    :iconName="iconName"
    :placeholder="placeholder"
    :allowClear="allowClear"
    :disabled="disabled"
    :autocomplete="autocomplete"
    @input="onInput"
    @blur="onBlur"
    @press-enter="pressEnter"
  >
    <template v-if="icon" #addonAfter>
      <cds-feather-icons
        :type="icon"
        size="14"
        style="cursor: pointer"
        @click="iconClick(iconName)"
      />
    </template>
    <template #prefix>
      <slot></slot>
    </template>
  </a-input>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'Input',
  props: {
    value: VueTypes.string.def(''),
    placeholder: VueTypes.string,
    maxlength: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    showCount: VueTypes.bool.def(false),
    allowClear: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    size: VueTypes.string.def('small'),
    icon: VueTypes.string,
    iconName: VueTypes.string,
    readOnly: VueTypes.bool.def(false),
    autocomplete: VueTypes.string.def('off'),
  },
  emits: ['press-enter'],
  setup(props) {
    const valueInput = ref(props.value);
    return {
      valueInput,
    };
  },
  methods: {
    iconClick() {
      this.$emit('iconClick', this.iconName);
    },
    onInput(e) {
      this.valueInput = e.target.value
      this.$emit('update:value', this.valueInput);
    },
    onBlur() {
      this.$emit(
        'update:value',
        this.valueInput ? (this.valueInput + '').trim().replace(/\s+/g, ' ') : this.valueInput
      );
    },
    pressEnter() {
      this.$emit('press-enter');
    },
  },
  watch: {
    value(val) {
      this.valueInput = val;
    },
  },
});
</script>

<style scoped>
.ant-input-sm {
  padding: 7px 7px;
}
</style>
