import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';

const state = () => ({
  listFloor: [],
  loading: false,
  api: ConstantAPI.BUILDING,
  visible: false,
});

const actions = {
  async getFloorByBuildingId({ state }, id) {
    try {
      const response = await DataService.callApi(
        state.api.FLOOR_BY_BUILDING_ID,
        null,
        {
          buildingId: id,
        }
      );
      let success;
      checkResponse(response, () => {
        success = true;
        state.listFloor = response.data ? response.data : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getFloorByUnitId({ state }, id) {
    try {
      const response = await DataService.callApi(
        state.api.FLOOR_BY_UNIT_ID,
        null,
        {
          unitId: id,
        }
      );
      let success;
      checkResponse(response, () => {
        success = true;
        state.listFloor = response.data ? response.data : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
