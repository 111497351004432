import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';


const state = () => ({
  listAiBox: [],
  listPartner: [],
  api: ConstantAPI.AI_BOX,
});

const actions = {
  async getAllAiBox({ state }, params) {
    const response = await DataService.callApi(
      state.api.GET_LIST_AI_BOX,
      null,
      params
    );
    state.listAiBox = response.data? response.data:[]
  },
  async getPartner({ state }) {
    const response = await DataService.callApi(
      state.api.GET_PARTNER,
      null,
      null
    );
    state.listPartner = response? response.data:[]
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
