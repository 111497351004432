import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';

const state = () => ({
  loading: false,
  api: ConstantAPI.HOME_PAGE,
  visible: false,
  listBoxAI: [],
  listCameraStatus: [],
});

const actions = {
  async getBoxAI({ state }, params) {
    try {
      const response = await DataService.callApi(state.api.GET_BOX_AI, null, params);
      state.listBoxAI = response.data || []
    } catch (err) {
      console.log(err);
    }
  },
  async getCameraStatus({ state }) {
    try {
      const response = await DataService.callApi(state.api.GET_CAMERA_STATUS);
      state.listCameraStatus = response || []
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
