export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setList(state, list) {
    state.listEvent = list;
  },
  setTotal(state, total) {
    state.total = total
  }
};
