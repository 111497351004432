import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {getField, updateField} from 'vuex-map-fields';
import {DataService} from "@/dataService/dataService";
import {checkResponse} from "@/util/common-utils";
// import {notification} from "ant-design-vue";
// import {i18n} from "@/main";

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.EVENT,
  totalMonitor: 0,
  totalPieChart: 0,
  dataPieChart: [],
  barChart: {
    yAxis: [],
    series: []
  },
  listHumanMonitor: [],
  listEventMonitor: []
});

const actions = {
  async getStatisticEventMonitor({state, commit}, params) {
    const response = await DataService.callApi(state.api.MONITOR_STATISTIC, null, params);
    checkResponse(response, () => {
      commit('setPieChart', response.data);
      commit('setBarChart', response.data);
    });
  },
  async getStatisticHumanMonitor({state}, params) {
    const response = await DataService.callApi(state.api.HUMAN_MONITOR_STATISTIC, null, params);
    checkResponse(response, () => {
      state.listHumanMonitor = response.data;
    });
  },
  async getEventMonitor({state}, params) {
    const response = await DataService.callApi(state.api.MONITOR_EVENT, null, params);
    checkResponse(response, () => {
      state.listEventMonitor = response.data.content;
    });
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
