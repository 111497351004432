<template>
  <div class="max-w-full from-to-date">
    <a-row :gutter="50">
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item
          :label="
            isShowLabel ? (fromLabel ? fromLabel : t('common.from-date')) : ''
          "
          :name="fromName"
          :label-col="{ span: fromLabelCol }"
        >
          <DatePickerWrapper>
            <a-date-picker
              v-model:value="valueFrom"
              @change="dateFromChange"
              :disabled-date="disabledFromDate"
              :placeholder="fromLabel ? fromLabel : t('common.from-date')"
              :format="format"
              size="small"
            >
            </a-date-picker>
          </DatePickerWrapper>
        </a-form-item>
      </a-col>
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item
          :label="isShowLabel ? (toLabel ? toLabel : t('common.to-date')) : ''"
          :name="toName"
          :label-col="{ span: toLabelCol }"
        >
          <DatePickerWrapper>
            <a-date-picker
              v-model:value="valueTo"
              @change="dateToChange"
              :disabled-date="disabledToDate"
              :placeholder="toLabel ? toLabel : t('common.to-date')"
              :format="format"
              size="small"
            >
            </a-date-picker>
          </DatePickerWrapper>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { DatePickerWrapper } from '../date-picker/style';
import VueTypes from 'vue-types';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FromToDate',
  components: {
    DatePickerWrapper,
  },
  props: {
    dateFrom: VueTypes.object.def(),
    dateTo: VueTypes.object.def(),
    format: VueTypes.string.def('DD/MM/YYYY'),
    disabledDate: VueTypes.func.def(() => false),
    fromLabel: VueTypes.string.def(''),
    fromName: VueTypes.string,
    toLabel: VueTypes.string.def(''),
    toName: VueTypes.string,
    fromLabelCol: VueTypes.number.def(5),
    toLabelCol: VueTypes.number.def(6),
    isShowLabel: VueTypes.bool.def(true),
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const valueFrom = ref(props.dateFrom ? dayjs(props.dateFrom) : null);
    const valueTo = ref(props.dateTo ? dayjs(props.dateTo) : null);
    const disabledDate = ref(props.disabledDate);
    const disabledToDate = (current) => {
      // Can not select days before today and today
      return (
        current &&
        (disabledDate.value(current) ||
          (valueFrom.value && current < valueFrom.value))
      );
    };
    const disabledFromDate = (current) => {
      // Can not select days before today and today
      return (
        current &&
        (disabledDate.value(current) ||
          (valueTo.value && current > valueTo.value))
      );
    };
    const dateFromChange = (val) => {
      emit('update:dateFrom', val ? val.toDate() : null);
      emit('changeDateFrom', val ? val.toDate() : null);
    };
    const dateToChange = (val) => {
      emit('update:dateTo', val ? val.toDate() : null);
      emit('changeDateTo', val ? val.toDate() : null);
    };
    watch(
      () => props.dateFrom,
      (val) => (valueFrom.value = val ? dayjs(val) : null)
    );
    watch(
      () => props.dateTo,
      (val) => (valueTo.value = val ? dayjs(val) : null)
    );
    return {
      valueFrom,
      valueTo,
      dateFromChange,
      dateToChange,
      disabledToDate,
      disabledFromDate,
      t,
    };
  },
  methods: {
    change(val) {
      this.$emit(
        'update:value',
        (val || []).map((d) => d.toDate())
      );
    },
  },
  watch: {
    value(val) {
      this.valueDate = (val || []).map((d) => dayjs(d));
    },
  },
});
</script>

<style lang="scss" scoped>
.ant-picker-large {
  padding: 10.5px 11px 10.5px !important;
}
.from-to-date {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
