import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import {STATISTICAL_OBJECT} from '@/util/common-constant';
import dayjs from 'dayjs'

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.ACCESS_CONTROL,
  dataStranger: {},
  dataStaff: {},
  dataCustomer: {},
  dataVip: {},
  dataBlacklist: {},
  chartPieIn: null,
  chartPieOut: null,
  chartLineIn: {
    data: null,
    series: [],
  },
  chartLineOut: {
    data: null,
    series: [],
  },
  listCamera: [],
  listStranger: [],
  listQuantityStatistics: [],
  listQuantityStatisticsCamera: [],
  listProfileInOut: [],
  listEventHuman:[],
  chartMonthLineIn: {
    data: null,
    series: [],
  },
  chartMonthLineOut: {
    data: null,
    series: [],
  },
  totalIn: 0,
  totalOut: 0,
});

const actions = {
  async getData({ commit, state }, params) {
    commit('setLoading', true)
    const response = await DataService.callApi(state.api.DASHBOARD, null, params);
    checkResponse(response, () => {
      state.dataStranger = response.data.stranger
      state.dataStaff = response.data.staff
      state.dataCustomer = response.data.customer
      state.dataBlacklist = response.data.blacklist
      state.dataVip = response.data.vip
      commit('setChartPieIn')
      commit('setChartPieOut')
      commit('setChartLineIn', response.data.statisticIn ? response.data.statisticIn : [])
      commit('setChartLineOut', response.data.statisticOut ? response.data.statisticOut : [])
    });
    commit('setLoading', false)
  },
  async getDashboard({commit, state}) {
    const todayParams = {
      dateFrom: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      dateTo: dayjs().format('YYYY-MM-DD HH:mm:ss')
    };
    const monthParams = {
      chartBy: STATISTICAL_OBJECT.MONTH,
      dateFrom: dayjs(new Date(new Date().getFullYear(), 0)).format('YYYY-MM-DD HH:mm:ss'),
      dateTo: dayjs().format('YYYY-MM-DD HH:mm:ss')
    }
    const response = await DataService.callApi(state.api.DASHBOARD, null, todayParams);
    checkResponse(response, () => {
      commit('setTotal', response.data);
    });
    const responseMonth = await DataService.callApi(state.api.DASHBOARD, null, monthParams);
    checkResponse(responseMonth, () => {
      commit('setChartMonthLineIn', responseMonth.data.statisticIn ? responseMonth.data.statisticIn : [])
      commit('setChartMonthLineOut', responseMonth.data.statisticOut ? responseMonth.data.statisticOut : [])
    });
  },
  async download({ commit, state }, param) {
    const { t } = i18n.global;
    try {
      commit('setLoading', true);
      notification.info({
        message: t('common.notification'),
        description: t('common.exporting'),
        duration: 4,
      });
      const response = await DataService.getConfig(
        state.api.DOWNLOAD_FILE.url,
        param,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'thong_ke_khach_hang.xlsx');
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: t('common.notification'),
        description: t('common.export-success'),
        duration: 4,
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getAllCamera({ state }, params) {
    const response = await DataService.callApi(
      state.api.GET_ALL_CAMERA,
      null,
      params
    );
    checkResponse(response, () => {
      state.listCamera = response.data || [];
    });
  },
  async getStatisticStranger({ state, commit }, params) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.DASHBOARD_STRANGER,
        null,
        params
      );
      checkResponse(response, () => {
        state.listStranger = response.data;
      });
      commit('setLoading', false);
    } catch (e) {
      commit('setLoading', false);
      console.log(e);
    }
  },
  async getDataChart({ state }, params) {
    const response = await DataService.callApi(
      state.api.GET_DATA_CHART,
      null,
      params
    );
    checkResponse(response, () => {
      state.listQuantityStatistics = response.data.eventType
        ? response.data.eventType
        : [];
      state.listQuantityStatisticsCamera = response.data.camera
        ? response.data.camera
        : [];
    });
  },
  async getStatisticProfileInOut({state, commit}, params) {
    try {
      commit('setLoading', true)
      const response = await DataService.callApi(state.api.DASHBOARD_PROFILE_IN_OUT, null, params);
      checkResponse(response, () => {
        state.listProfileInOut = response.data;
      });
      commit('setLoading', false)
    } catch(e) {
      commit('setLoading', false)
    }
  },
  async getListEventHuman({state}, value) {
    const {params,seeMore} = value;
    try {
      const response = await DataService.callApi(state.api.GET_EVENT_INSTRUSION, null, params);
      checkResponse(response, () => {
        if(seeMore){
          state.listProfileInOut = state.listProfileInOut.concat(response.data.content);
        }
        else{
          state.listProfileInOut = response.data?response.data.content:[];
        }
       
      });
    } catch(e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
