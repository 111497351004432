<template>
  <p v-if="readOnly">{{ getText() }}</p>
  <a-tree-select
    v-else
    v-model:value="valueSelect"
    show-search
    style="width: 100%"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :placeholder="placeholder"
    allow-clear
    :tree-data="treeData"
    :treeCheckStrictly="treeCheckStrictly"
    :fieldNames="fieldNames"
    :treeCheckable="treeCheckable"
    :filterTreeNode="filterTreeNode"
    :showSearch="true"
    :max-tag-count="maxTagCount"
    :max-tag-text-length="maxTagTextLength"
    @change="change"
  >
  </a-tree-select>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import _ from 'lodash';

export default defineComponent({
  name: 'TreeSelect',
  props: {
    treeData: VueTypes.array.def([]),
    value: VueTypes.oneOfType([
      VueTypes.array,
      VueTypes.string,
      VueTypes.number,
    ]).def(),
    checkStrictly: VueTypes.bool.def(true),
    fieldNames: VueTypes.object,
    placeholder: VueTypes.string.def(''),
    readOnly: VueTypes.bool.def(false),
    treeCheckable: VueTypes.bool.def(false),
    treeCheckStrictly: VueTypes.bool.def(false),
    maxTagCount: VueTypes.number.def(2),
    maxTagTextLength: VueTypes.number.def(20),
  },
  setup(props, { emit }) {
    const valueSelect = ref(props.value);
    const treeData = ref(props.treeData);
    const fieldNames = ref(props.fieldNames);
    watch(
      () => props.value,
      (val) => (valueSelect.value = val)
    );
    const filterTreeNode = (inputValue, treeNode) => {
      return treeNode.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    };
    const flattenData = (node) => {
      let result = [node];
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          result = result.concat(flattenData(child));
        });
      }
      return result;
    };
    const flattenAll = (dataArray) => {
      let flatArray = [];
      dataArray.forEach((item) => {
        flatArray = flatArray.concat(flattenData(item));
      });
      return flatArray;
    };
    const change = (val) => {
      if(props.treeCheckStrictly) {
        const allValues = new Set(val.map((item) => item.value));
        const removedValues = props.value.filter(
            (item) => !allValues.has(item.value)
        );
        //Bỏ chọn all children
        props.value.forEach((value) => {
          const node = findNodeByValue(props.treeData, value.value);
          if (node && node.children && props.value.filter(item => item.value === node.id).length > 0 && !allValues.has(node.id)) {
            removeAllChildren(node, allValues);
          }
        });
        //Thêm all children
        val.forEach((value) => {
          const node = findNodeByValue(props.treeData, value.value);
          if (node && node.children && props.value.filter(item => item.value === node.id).length < 1 && allValues.has(node.id)) {
            getAllChildren(node, allValues, removedValues);
            if(node.parentId) {
              allValues.add(node.parentId)
            }
          }
        });
        const treeFlat = flattenAll(props.treeData);
        const listVal = treeFlat
            .filter((item) => allValues.has(item.id))
            .map((item) => ({
              value: item.id,
              label: item.label,
            }));
        emit('update:value', listVal);
      } else {
        emit('update:value', val);
      }
    };
    const findNodeByValue = (treeData, value) => {
      for (const node of treeData) {
        if (node.id === value) {
          return node;
        }
        if (node.children) {
          const found = findNodeByValue(node.children, value);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };
    const getAllChildren = (node, allValues, removedValues) => {
      node.children.forEach((child) => {
        if (removedValues.length > 0 && removedValues[0].value === child.id) {
          return
        }
        allValues.add(child.id);
        if (child.children) {
          getAllChildren(child, allValues);
        }
      });
    };
    const removeAllChildren = (node, allValues) => {
      node.children.forEach((child) => {
        allValues.delete(child.id);
        if (child.children) {
          removeAllChildren(child, allValues);
        }
      });
    };
    const getText = computed(() => () => {
      const flattenData = treeData.value.reduce((newArr, arr) => {
        const { children, ...rest } = arr;
        newArr.push(rest);
        return newArr.concat(children);
      }, []);
      const parent = flattenData.find(
        (e) =>
          e &&
          !_.isNil(valueSelect.value) &&
          e[fieldNames.value.value] == valueSelect.value
      );
      console.log(valueSelect.value, parent);
      if (parent) return parent[fieldNames.value.label];
    });
    return {
      valueSelect,
      filterTreeNode,
      change,
      getText,
    };
  },
});
</script>

<style scoped></style>
