import {i18n} from "@/main";
import { EVENT_DOOR } from "@/util/common-constant";
export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setChartLineCompare(state, data) {
    const {t} = i18n.global
    state.chartLineCompare.series = [
      {
        name: t('common.now'),
        data: data.now.map(e => ([e.key_as_string, e.doc_count])),
        type: 'line',
        showSymbol: false,
        color: '#F2AE3E',
      },
      {
        name: t('common.day_ago'),
        data: data.dayAgo.map(e => ([e.key_as_string, e.doc_count])),
        type: 'line',
        showSymbol: false,
        color: '#4C9008',
      },
      {
        name: t('common.week_ago'),
        data: data.weekAgo.map(e => ([e.key_as_string, e.doc_count])),
        type: 'line',
        showSymbol: false,
        color: '#08B0D5',
      },
    ]
  },
  setChartLineAccessControl(state, data) {
    const {t} = i18n.global;
    state.chartLineAccessControl.data = data.map((e) => e.key_as_string);
    state.chartLineAccessControl.series = [
      {
        name: t('event.ZONE_ACCESS_CONTROL_OUT'),
        type: 'bar',
        data: data.map(e => {
          return e.in_out.buckets.filter(ec => ec.key === EVENT_DOOR.OUT).map(ecc => ecc.doc_count);
        }).flat(),
        color: '#F2AE3E',
        barWidth: 7,
      },
      {
        name: t('event.ZONE_ACCESS_CONTROL_IN'),
        type: 'bar',
        data: data.map(e => {
          return e.in_out.buckets.filter(ec => ec.key === EVENT_DOOR.IN).map(ecc => ecc.doc_count);
        }).flat(),
        color: '#08B0D5',
        barWidth: 7,
      },
    ];
  }
};
