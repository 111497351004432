import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.province,
});

const actions = {
  async getAll({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.GET_ALL);
      commit('setListProvince', response.data);
    } catch (err) {
      console.log(err);
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
