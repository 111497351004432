import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';

const state = () => ({
  newDevice: 0,
  newPartner: 0,
  api: ConstantAPI.HOME,
  avarage: {},
  growth: [],
  growthUp: [],
  growthDown: [],
  dataTable: [],
  listMonth: [],
  listDevice: [],
  listPartner: [],
});
const actions = {
  async getAll({ state, commit }, params) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL, null, {
        ...params,
      });
      let success;
      checkResponse(response, () => {
        state.newDevice = response.data.partnerDevice.newDevice
          ? response.data.partnerDevice.newDevice
          : 0;
        state.newPartner = response.data.partnerDevice.newPartner
          ? response.data.partnerDevice.newPartner
          : 0;
        state.growth = response.data.partnerDevice.growth
          ? response.data.partnerDevice.growth
          : [];
        state.growthUp = response.data.partnerDevice.growthUp
          ? response.data.partnerDevice.growthUp
          : [];
        state.growthDown = response.data.partnerDevice.growthUp
          ? response.data.partnerDevice.growthUp
          : [];
        state.avarage = response.data.avarage ? response.data.avarage : [];
        state.dataTable = response.data.potential
          ? response.data.potential
          : [];
      });
      commit(
        'setData',
        response.data.partnerDevice.statistic
          ? response.data.partnerDevice.statistic
          : {}
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
