import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import { DEVICE_TYPE } from '@/util/common-constant';
import { showConfirm } from '@/util/confirm';
import { notification } from 'ant-design-vue';
const state = () => ({
  api: ConstantAPI.DEVICE,
  listDevice: [],
  listCameraScan: [],
  linkStream: null,
  isShowSettingBox: false,
  recordBox: null,
  isShowSettingCamera: false,
  recordDevice: null,
  isShowSettingDevice: false,
  listScanIp: [],
  recordCamera: null,
  aiFlowDTOList: [],
  listScanHistory: [],
});

const actions = {
  async getListDevice({ state }) {
    try {
      const listData = [];
      const response = await DataService.callApi(
        state.api.GET_LIST_DEVICE,
        null,
        null
      );
      response.groupTreeDTOList.map((item) => {
        if (item.type === DEVICE_TYPE.BOX) {
          listData.push(item);
        }
        item.childGroups.map((p) => {
          listData.push(p);
        });
      });
      response.doorDTOList.map((item) => {
        listData.push({
          ...item,
          type: DEVICE_TYPE.DOOR,
        });
      });
      state.listDevice = listData;
    } catch (err) {
      console.log(err);
    }
  },
  async scanCameraInBox({ state }, payload) {
    const response = await DataService.callApi(
      state.api.SCAN_CAMERA_IN_BOX,
      payload,
      null
    );
    state.listCameraScan = response ? response[0].cameraDTOs : [];
  },
  async getStreamCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.STREAM_CAMERA,
        null,
        params
      );
      state.linkStream = response;
    } catch (err) {
      console.log(err);
    }
  },
  handleShowSettingBox({ commit, state }, params) {
    const { value, record } = params;
    if (!value) {
      commit('resetData');
    }
    state.isShowSettingBox = value;
    state.recordBox = record;
  },
  handleShowSettingCamera({ commit, state }, params) {
    const { value, record } = params;
    if (!value) {
      commit('resetData');
    }
    state.isShowSettingCamera = value;
    state.recordCamera = record;
  },
  handleShowSettingDevice({ commit, state }, params) {
    const { value, record } = params;
    if (!value) {
      commit('resetData');
    }
    state.isShowSettingDevice = value;
    state.recordDevice = record;
  },
  async getDiscoveryInfo({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_DISCOVERY_INFO,
        params,
        null
      );
      let data = [];
      if (response) {
        response.data.map((item) => {
          if (item.fromIp) {
            data.push({
              fromIp: item.fromIp,
              toIp: item.toIp,
              usernames: item.usernames[0],
              passwords: item.passwords[0],
            });
          }
        });
      }
      state.listScanHistory = data;
    } catch (err) {
      console.log(err);
    }
  },
  async createDiscoveryInfo({ state }, params) {
    await DataService.callApi(
      state.api.CREATE_DISCOVERY_IN_GROUPS,
      params,
      null
    );
  },
  async addCameraInBox({ state }, params) {
    await DataService.callApi(state.api.ADD_CAM_IN_BOX, params, null);
  },
  async getCameraInGroup({ state }, params) {
    console.log("params",params);
    try {
      const response = await DataService.callApi(
        state.api.GET_CAMERA_IN_GROUP,
        null,
        params
      );
      state.listCameraScan = response ? response.discoveredCameraDTOs : [];
    } catch (err) {
      console.log(err);
    }
  },
  async createZone({ state }, params) {
    const response = await DataService.callApi(
      state.api.CREATE_ZONE,
      params,
      null
    );
    return response;
  },
  async createAiFlows({ state }, params) {
    const response = await DataService.callApi(
      state.api.CREATE_AI_FLOW,
      params,
      null
    );
    return response;
  },
  async addZoneAiFlows({ state }, params) {
    const response = await DataService.callApi(
      state.api.ADD_ZONE_AI_FLOWS,
      params,
      null
    );
    return response;
  },
  async addAiInCamera({ state }, params) {
    const response = await DataService.callApi(
      state.api.ADD_AI_CAMERA,
      params,
      null
    );
    return response;
  },
  async getListAiFlows({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_AI_FLOWS,
        null,
        params
      );
      state.aiFlowDTOList = response ? response[0].aiFlowDTOList : [];
    } catch (err) {
      console.log(err);
    }
  },
  async removeAiFlows({ state }, params) {
    if (await showConfirm('Bạn có chắc muốn xoá bài toán này')) {
      await DataService.callApi(state.api.REMOVE_AI_FLOWS, params, null);
      notification.success({
        message: 'Thông báo',
        description: 'Xoá thành công',
        duration: 4,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
