import mutations from '../articles/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import {
  FORM_MODE,
  UNIT_TYPE,
  BASE_WORKING_TIME,
} from '@/util/common-constant';
import { checkResponse, flatten } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { showConfirm } from '@/util/confirm';
import { trimValueObj } from '@/util/common-utils';
import _ from 'lodash';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  articles: {},
  api: ConstantAPI.UNIT,
  visible: false,
  selectedItem: null,
  formMode: FORM_MODE.VIEW,
  treeUnit: [],
  treeFlat: [],
  formState: null,
  listAllDepartment: [],
  listUnit: [],
});
const addKey = (data) => {
  for (let item of data) {
    item.key = item.departmentId ? item.departmentId : item.unitId;
    item.type = item.departmentId ? UNIT_TYPE.PHONG_BAN : UNIT_TYPE.DON_VI;
    if (item.departmentId) {
      item.parentId = item.unitId;
    }
    item.baseWorkTime = item.baseWorkTimeByDay
      ? BASE_WORKING_TIME.BY_DATE
      : item.baseWorkTimeByHour
      ? BASE_WORKING_TIME.BY_HOUR
      : null;
    if (item.children && item.children.length > 0) {
      addKey(item.children);
    }
  }
};
const actions = {
  preView({ state }) {
    state.formMode = FORM_MODE.VIEW;
  },
  preUpdate({ state }) {
    state.formMode = FORM_MODE.UPDATE;
  },
  preCreate({ state }, { type, parent, buildingId }) {
    state.formMode = FORM_MODE.CREATE;
    state.selectedItem = null;
    if (type === UNIT_TYPE.DON_VI) {
      state.formState = {
        unitName: '',
        showName: '',
        contactPersonName: '',
        contactPhone: '',
        note: '',
        description: '',
        logo: '',
        type: UNIT_TYPE.DON_VI,
        buildingId: buildingId === '0' ? null : buildingId,
        floorOrders: [],
        email: '',
        status: 1,
        baseWorkTime: BASE_WORKING_TIME.BY_HOUR,
        baseWorkTimeByDay: 8,
        baseWorkTimeByHour: 192,
        workShiftTime: '',
      };
    } else {
      state.formState = {
        departmentName: '',
        unitId: parent.key,
        location: '',
        contactPersonName: '',
        contactPhone: '',
        note: '',
        description: '',
        type: UNIT_TYPE.PHONG_BAN,
        workShiftDay1: null,
        workShiftDay2: null,
        workShiftDay3: null,
        workShiftDay4: null,
        workShiftDay5: null,
        workShiftDay6: null,
        workShiftDay7: null,
        floorOrders: [],
        buildingId: buildingId,
        status: 1,
        inheritWorkTime: false,
        workShiftTime: '',
      };
    }
  },
  resetForm({ state }) {
    state.formState = null;
    state.selectedItem = null;
    state.formMode = FORM_MODE.VIEW;
  },
  selectItem({ state }, idItem) {
    if (!state.selectedItem || idItem !== state.selectedItem.key) {
      if (idItem) {
        for (let i in state.treeFlat) {
          if (state.treeFlat[i].key === idItem) {
            state.selectedItem = _.cloneDeep(state.treeFlat[i]);
            state.formState = _.cloneDeep(state.treeFlat[i]);
            let arr = state.formState.floorOrders
              ? state.formState.floorOrders.split(',')
              : [];
            state.formState.floorOrders = [];
            for (let i in arr) {
              state.formState.floorOrders.push(Number(arr[i]));
            }
            break;
          }
        }
      } else {
        state.selectedItem = null;
        state.formState = null;
      }
    }
  },
  changeAction({ state }, formMode) {
    state.formMode = formMode;
  },
  resetDataFormState({ state }) {
    state.formState = _.cloneDeep(state.selectedItem);
    let arr = state.formState.floorOrders
      ? state.formState.floorOrders.split(',')
      : [];
    state.formState.floorOrders = [];
    for (let i in arr) {
      state.formState.floorOrders.push(Number(arr[i]));
    }
    state.formState.baseWorkTime = BASE_WORKING_TIME.BY_DATE;
    state.formState.baseWorkTimeByDay = 8;
    state.formState.baseWorkTimeByHour = 192;
  },
  async getListUnit({ state }, params) {
    try {
      trimValueObj(params);
      const response = await DataService.callApi(
        state.api.LIST_UNIT,
        null,
        params
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        state.treeUnit = response.data.content ? response.data.content : [];
        addKey(state.treeUnit);
        state.treeFlat = response.data.content
          ? flatten(response.data.content)
          : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async updateUnit({ state }) {
    const { t } = i18n.global;
    try {
      let payload = _.cloneDeep(state.formState);
      if (payload.baseWorkTime === BASE_WORKING_TIME.BY_HOUR) {
        delete payload.baseWorkTimeByDay;
      } else delete payload.baseWorkTimeByHour;
      delete payload.baseWorkTime;
      trimValueObj(payload);
      const response = await DataService.callApi(state.api.UPDATE_UNIT, {
        ...payload,
        floorOrders: state.formState.floorOrders.join(','),
      });
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('unit.update-unit-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async updateDepartment({ state }) {
    const { t } = i18n.global;
    try {
      let payload = _.cloneDeep(state.formState);
      if (payload.baseWorkTime === BASE_WORKING_TIME.BY_HOUR) {
        delete payload.baseWorkTimeByDay;
      } else delete payload.baseWorkTimeByHour;
      delete payload.baseWorkTime;
      const response = await DataService.callApi(state.api.UPDATE_DEPARTMENT, {
        ...payload,
        floorOrders: state.formState.floorOrders.join(','),
      });
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('unit.update-department-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async createDepartment({ state }, listMembers) {
    const { t } = i18n.global;
    try {
      let payload = { ...state.formState };
      let ids = [];
      for (let i in listMembers) {
        ids.push(listMembers[i].id);
      }
      payload.profiles = ids.join(',');
      payload.floorOrders = payload.floorOrders.join(',');
      if (payload.baseWorkTime === BASE_WORKING_TIME.BY_HOUR) {
        delete payload.baseWorkTimeByDay;
      } else delete payload.baseWorkTimeByHour;
      delete payload.baseWorkTime;
      trimValueObj(payload);
      const response = await DataService.callApi(
        state.api.CREATE_DEPARTMENT,
        payload
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('unit.create-department-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success ? response : success;
    } catch (err) {
      console.log(err);
    }
  },
  async createUnit({ state }) {
    const { t } = i18n.global;
    try {
      let payload = _.cloneDeep(state.formState);
      if (payload.baseWorkTime === BASE_WORKING_TIME.BY_HOUR) {
        delete payload.baseWorkTimeByDay;
      } else delete payload.baseWorkTimeByHour;
      delete payload.baseWorkTime;
      trimValueObj(payload);
      const response = await DataService.callApi(state.api.CREATE_UNIT, {
        ...payload,
        floorOrders: state.formState.floorOrders.join(','),
      });
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('unit.create-unit-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success ? response : success;
    } catch (err) {
      console.log(err);
    }
  },
  async deleteUnit({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('unit.confirm-delete-unit'))) {
        const response = await DataService.callApi(state.api.DELETE_UNIT, {
          unitId: params.unitId,
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('unit.delete-unit-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async deleteDepartment({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('unit.confirm-delete-department'))) {
        const response = await DataService.callApi(
          state.api.DELETE_DEPARTMENT,
          {
            departmentId: params.departmentId,
          }
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('unit.delete-department-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async deleteDepartmentProfile({ state }, data) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('unit.confirm-delete-user'))) {
        const response = await DataService.callApi(
          state.api.DELETE_DEPARTMENT_PROFILE,
          {
            profileIds: [data.id],
          }
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('unit.delete-user-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async deleteUnitProfile({ state }, data) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('unit.confirm-delete-unit-user'))) {
        const response = await DataService.callApi(
          state.api.DELETE_UNIT_PROFILE,
          {
            profileIds: [data.id],
          }
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('unit.delete-unit-user-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async addDepartmentUser({ state }, { departmentId, ids }) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        state.api.ADD_DEPARTMENT_PROFILE,
        {
          departmentId: departmentId,
          profileIds: ids,
        }
      );
      let success = false;

      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('unit.add-user-success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async listDepartment({ state }, params) {
    try {
      trimValueObj(params);
      const response = await DataService.callApi(
        state.api.LIST_DEPARTMENT,
        null,
        params
      );
      let success = false;

      checkResponse(response, () => {
        success = true;
        state.listAllDepartment = response.data.content
          ? response.data.content
          : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  changeWorkTime({ state }, { data, time }) {
    state.formState = Object.assign(state.formState, data);
    state.formState.workShiftTime = time;
  },
  async exportDepartmentProfile({ state }, params) {
    const { t } = i18n.global;
    try {
      trimValueObj(params);
      notification.info({
        message: t('common.notification'),
        description: t('common.exporting'),
        duration: 4,
      });
      const response = await DataService.getConfig(
        state.api.EXPORT_DEPARTMENT_PROFILE.url,
        params,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `department-members-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: t('common.notification'),
        description: t('common.export-success'),
        duration: 4,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async exportUnitProfile({ state }, params) {
    const { t } = i18n.global;
    try {
      trimValueObj(params);
      notification.info({
        message: t('common.notification'),
        description: t('common.exporting'),
        duration: 4,
      });
      const response = await DataService.getConfig(
        state.api.EXPORT_UNIT_PROFILE.url,
        params,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `unit-members-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: t('common.notification'),
        description: t('common.export-success'),
        duration: 4,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getAllUnit({ state }, params) {
    try {
      trimValueObj(params);
      const response = await DataService.callApi(
        state.api.GET_ALL_UNIT,
        null,
        params
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        state.listUnit = response.data.content ? response.data.content : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
