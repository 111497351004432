import {i18n} from "@/main";
// import { graphic } from 'echarts';
import { PROFILE_TYPE_OPTIONS_1 } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPieChart(state, data) {
    state.dataPieChart = data.stranger ? data.stranger : [];
    state.totalPieChart = data.stranger ? data.stranger.reduce((a, b) => {
      return a + b.count
    }, 0) : 0;
  },
  setBarChart(state, data) {
    const {t} = i18n.global
    state.totalMonitor = data.human.reduce((a, b) => {
      return a + b.doc_count
    }, 0);
    state.barChart.yAxis = data.human.map(e => e.key_as_string);
    const newListProfile = PROFILE_TYPE_OPTIONS_1.map(e => t(e.label));
    const newData = data.human.map(e => {
      return PROFILE_TYPE_OPTIONS_1.map((pf) => (
      {
        col: e.key_as_string,
        label: t(pf.label),
        count: e.in_out.buckets && e.in_out.buckets.find(ec => ec.key === pf.value) ? e.in_out.buckets.find(ec => ec.key === pf.value).doc_count : 0
      }))
    });
    state.barChart.series = newListProfile.map((name) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series',
        },
        data: newData.map(e => e.find(ec => ec.label === name).count),
        barWidth: 50,
        label: {
          show: true,
          formatter: (params) => {
            const percent = params.value / data.human.find(e => e.key_as_string === params.name).doc_count * 100
            return percent >= 1 ? `${percent.toFixed(1)}%` : '';
          },
        },
      }
    });
  },
};
