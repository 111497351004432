import mutations from '@/vuex/modules/document/mutations';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
// import { checkResponse } from '@/util/common-utils';
// import { notification } from 'ant-design-vue';
// import { showConfirm } from '@/util/confirm';
import ConstantAPI from '@/config/ConstantAPI';

const state = () => ({
  api: ConstantAPI.dashboard,
  listExitEarly: [],
  listLated: [],
  reportExitEarly: [],
  reportLated: [],
  reportDayOff: [],
});

const actions = {
  async getTopExitEarly({state}, params) {
    try {
      const response = await DataService.callApi(state.api.GET_TOP_EXIT_EARLY, null, params);
      state.listExitEarly = response.data;
    } catch(err) {
      console.log(err)
    }
  },
  async getTopLated({state}, params) {
    try {
      const response = await DataService.callApi(state.api.GET_TOP_LATED, null, params);
      state.listLated = response.data;
    } catch(err) {
      console.log(err)
    }
  },
  async reportExitEarly({state}, params) {
    try {
      const response = await DataService.callApi(state.api.GET_REPORT_EXIT_EARLY, null, params);
      state.reportExitEarly = response.data;
    } catch(err) {
      console.log(err)
    }
  },
  async reportLated({state}, params) {
    try {
      const response = await DataService.callApi(state.api.GET_REPORT_LATED, null, params);
      state.reportLated = response.data;
    } catch(err) {
      console.log(err)
    }
  },
  async reportDayOff({state}, params) {
    try {
      const response = await DataService.callApi(state.api.GET_REPORT_DAY_OFF, null, params);
      state.reportDayOff = response.data;
    } catch(err) {
      console.log(err)
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
