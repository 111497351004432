// import {i18n} from "@/main";
// import { graphic } from 'echarts';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLineChart(state, data) {
    state.dataLineChart.xAxis = data.statisticComplete ? data.statisticComplete.map(e => e.time) : [];
    state.dataLineChart.series = [
      {
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        lineStyle: {
          color: '#F2AE3E',
        },
        data: data.statisticOver ? data.statisticOver.map(e => e.count) : [],
      },
      {
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        lineStyle: {
          color: '#4C9008',
        },
        data: data.statisticComplete ? data.statisticComplete.map(e => e.count) : [],
      },
      {
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        lineStyle: {
          color: '#08B0D5',
        },
        data: data.statisticNoCheck ? data.statisticNoCheck.map(e => e.count) : [],
      },
    ]
  }
};
