import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { FORM_MODE } from '@/util/common-constant';
import { checkResponse, getNameProfile } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import { i18n } from '@/main';

const state = () => ({
  list: [],
  loading: false,
  procedure: {},
  categories: [],
  api: ConstantAPI.profileManagement,
  visible: false,
  listDepartment: [],
  listProfileType: [],
  listUnit: [],
  listProfile: [],
  profile: {},
  total: 0,
  action: FORM_MODE.CREATE,
  profileName: null,
  listDepartmentByUnit: [],
  listAllProfile: [],
  profileInfo: {},
  listHistoryImportFile: [],
  totalHistoryImportFile: 0,
  listProfileLeaveApply: []
});

const actions = {
  async setAction({ state }, action) {
    state.action = action;
  },
  async create({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.CREATE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success', {
              name: t('common.profile'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getById({ commit, state }, id) {
    try {
      const response = await DataService.callApi({
        method: 'GET',
        url: `${state.api.GET_BY_IDS.url + id}`,
      });
      commit('setProfile', response.data);
      commit(
        'setProfileName',
        `${response.data.lastName} ${response.data.firstName}`
      );
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(state.api.UPDATE, payload);
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('common.profile'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_delete'))) {
        const response = await DataService.delete(
          state.api.DELETE.url + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.delete_success', {
              name: t('common.profile'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
  async getAllUnit({ commit, state }) {
    state.listDepartment = [];
    try {
      const response = await DataService.callApi(state.api.GET_ALL_UNIT);
      state.listUnit = response.data.content ? response.data.content : [];
      state.listUnit.forEach((e) => {
        if (e.children) {
          state.listDepartment = state.listDepartment.concat(e.children);
        }
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getDepartmentByUnitId({ commit, state }, unitId) {
    try {
      if (unitId) {
        const response = await DataService.callApi(
          state.api.GET_BY_UNIT_ID,
          null,
          { unitId, size: 9999 }
        );
        state.listDepartmentByUnit = response.data.content
          ? response.data.content
          : [];
      } else {
        state.listDepartmentByUnit = [];
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getProfileType({ commit, state }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.APP_PARAM.SEARCH,
        null,
        { type: 'Profile-type' }
      );
      state.listProfileType = response.data;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getAll({ state }, { params, isReset }) {
    const { t } = i18n.global;
    try {
      if (isReset) {
        state.listProfile = [];
      }
      const response = await DataService.callApi(
        state.api.GET_ALL,
        null,
        params
      );
      let success;
      checkResponse(
        response,
        () => {
          success = true;
          state.listProfile = response.data.content
            ? state.listProfile.concat(response.data.content)
            : state.listProfile;
          state.total = response.data.totalElements;
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('unit.get-profile-fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getAllProfileInUnitDpm({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_NAME,
        null,
        params
      );
      let success;
      checkResponse(response, () => {
        success = true;
        state.listAllProfile = response.data ? response.data : [];
        state.listAllProfile.map((e) => {
          e.name = getNameProfile(e);
        });
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getAllProfile({state}, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_NAME,
        null,
        params
      );
      let success;
      checkResponse(response, () => {
        success = true;
        state.listProfileLeaveApply = response.data ? response.data : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getInfo({ commit, state }) {
    let isSuccess = false;
    try {
      const response = await DataService.callApi(state.api.GET_INFO);
      checkResponse(
        response,
        (res) => {
          state.profileInfo = res;
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async importProfileExcel({ state }, params) {
    try {
      let response = await DataService.callApi(state.api.IMPORT_EXCEL, params);
      let success;
      checkResponse(response, () => {
        success = true;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  resetProcess({ state }) {
    state.percentImportProfile = 0;
  },
  setProcess({ state }, percent) {
    state.percentImportProfile = percent;
  },
  async getHistoryImportFile({state}, params) {
    try {
      const response = await DataService.callApi(state.api.HISTORY_IMPORT_FILE, null, params);
      let success;
      checkResponse(response, () => {
        state.listHistoryImportFile = response.data.content;
        state.totalHistoryImportFile = response.data.totalElements;
        success = true;
      });
      return success;
    } catch(err) {
      console.log(err)
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
