import mutations from '../articles/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';

const state = () => ({
  workShift: [],
  api: ConstantAPI.WORK_SHIFT,
  total: 0,
});

const actions = {
  async getAll({ state }, params) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        state.api.GET_ALL,
        null,
        params
      );
      let success;
      checkResponse(
        response,
        () => {
          success = true;
          state.workShift = response.data.content ? response.data.content : [];
          state.total = response.data.totalElements;
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('unit.get-work-shift-fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
