import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {getField, updateField} from 'vuex-map-fields';
import {DataService} from "@/dataService/dataService";
import {checkResponse} from "@/util/common-utils";
// import {notification} from "ant-design-vue";
// import {i18n} from "@/main";

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.STATISTIC,
  chartLineCompare: {
    series: []
  },
  chartLineAccessControl: {
    data: [],
    series: [],
  }
});

const actions = {
  async getStatisticCompare({state, commit}, params) {
    try {
      commit('setLoading', true)
      const response = await DataService.callApi(state.api.COMPARE_ACCESS_CONTROL, null, params);
      checkResponse(response, () => {
        commit('setChartLineCompare', response.data)
      });
      commit('setLoading', false)
    } catch(e) {
      commit('setLoading', false)
    } 
  },
  async getStatisticAccessControl({state, commit}, params) {
    try {
      commit('setLoading', true)
      const response = await DataService.callApi(state.api.ACCESS_CONTROL, null, params);
      checkResponse(response, () => {
        commit('setChartLineAccessControl', response.data)
      });
      commit('setLoading', false)
    } catch(e) {
      commit('setLoading', false)
    } 
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
