import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {showConfirm} from "@/util/confirm";
import {checkResponse, getNameProfile} from "@/util/common-utils";
import {notification} from "ant-design-vue";
import {i18n} from "@/main";
import {COMMA} from "@/util/common-constant";

const state = () => ({
  loading: false,
  api: ConstantAPI.ALERT_CONFIGURATION,
  visible: false,
  listAll: [],
  listCamera: [],
  listProfile: [],
  alertDetail: null,
  loadingProfile: false,
  notifySetting: null,
});

const actions = {
  async getAll({ state }, params) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL, null, params);
      state.listAll = response.data.content || []
    } catch (err) {
      console.log(err);
    }
  },
  async getAllCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
          ConstantAPI.CAMERA.GET_CAMERA_IN_GROUP,
          null,
          params
      )
      state.listCamera = response.data || []
    } catch (err) {
      console.log(err);
    }
  },
  async getProfiles({ state }, params) {
    state.loadingProfile = true
    const {t} = i18n.global
    try {
      if (params) {
        const response = await DataService.callApi(
            state.api.GET_PROFILE_BY_IDS_OR_TYPE,
            params,
        )
        state.listProfile = response.data.content.map(e => ({...e, fullName: getNameProfile(e)})) || []
        state.listProfile.unshift({fullName: t('common.all'), id: 'all', profileUuid: 'all'})
      } else {
        state.listProfile = []
      }
      state.loadingProfile = false
    } catch (err) {
      console.log(err);
    }
  },
  resetForm({ state }) {
    state.alertDetail = null
    state.listProfile = []
  },
  async getAlertById({ commit, state }, id) {
    try {
      commit('setLoading', true)
      const response = await DataService.callApi({
        url: state.api.GET_ALERT_BY_ID.url + id,
        method: state.api.GET_ALERT_BY_ID.method,
      })
      state.alertDetail = {
        ...response.data,
        profileIds: response.data.profileIds ? response.data.profileIds.split(COMMA) : [],
        cameraIds: response.data.cameraIds ? response.data.cameraIds.split(COMMA) : [],
        profileType: response.data.profileType ? response.data.profileType.split(COMMA) : [],
        eventType: response.data.eventType ? response.data.eventType.split(COMMA) : [],
        profileUUIds: response.data.profileUUIds ? response.data.profileUUIds.split(COMMA) : [],
      }
      commit('setLoading', false)
    } catch (err) {
      console.log(err);
    }
  },
  async create({ commit, state }, payload) {
    try {
      const {t} = i18n.global
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(state.api.CREATE, payload);
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    try {
      const {t} = i18n.global
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(state.api.UPDATE, payload);
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_delete'))) {
        const response = await DataService.callApi(
            state.api.DELETE, {ids: rows.map((e) => e.id)}
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.delete_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getNotifySetting({state, commit}) {
    try {
      commit('setLoading', true)
      const response = await DataService.callApi({
        url: state.api.GET_NOTIFY_SETTING.url,
        method: state.api.GET_NOTIFY_SETTING.method,
      })
      state.notifySetting = response.data;
      commit('setLoading', false)
    } catch (err) {
      console.log(err);
    }
  },
  async updateNotifySetting({state, commit}, payload) {
    try {
      const {t} = i18n.global
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(state.api.UPDATE_NOTIFY_SETTING, payload);
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
    notifySetting: (state) =>
        state.notifySetting ? state.notifySetting : null,
  },
};
