import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
  currentNotification: {},
});

const actions = {
  setCurrentNotification({ state }, data) {
    state.currentNotification = data
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
    currentNotification: (state) =>
        state.currentNotification ? state.currentNotification : null,
  },
};
