<template>
  <a-modal
    v-model:visible="isVisible"
    :title="title"
    v-bind="hideFooter ? { footer: null } : {}"
    :destroyOnClose="destroyOnClose"
    :maskClosable="maskClosable"
    :wrapClassName="wrapClassName"
    :maskStyle="maskStyle"
    :width="width"
    @ok="handleOk"
  >
    <template #footer v-if="!hideFooter">
      <slot name="footer">
        <cds-button key="back" type="light" size="small" @click="onCancel">{{
          cancelTitle
        }}</cds-button>
        <cds-button key="submit" type="primary" size="small" @click="handleOk"
          >{{ acceptTitle }}
        </cds-button>
      </slot>
    </template>

    <a-spin :spinning="loading">
      <slot></slot>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'Modal',
  props: {
    visible: VueTypes.bool.def(true),
    loading: VueTypes.bool.def(false),
    title: VueTypes.string,
    destroyOnClose: VueTypes.bool.def(true),
    cancelTitle: VueTypes.string.def('Hủy bỏ'),
    acceptTitle: VueTypes.string.def('Đồng ý'),
    hideFooter: VueTypes.bool.def(false),
    maskClosable: VueTypes.bool.def(false),
    width: VueTypes.string.def('600px'),
    wrapClassName: VueTypes.string,
    maskStyle: VueTypes.object
  },
  data(props) {
    let isVisible = ref(props.visible);
    return {
      className: this.class,
      isVisible,
    };
  },
  watch: {
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      if (!val) {
        this.onCancel();
      }
    },
  },
  methods: {
    handleOk() {
      this.$emit('onOk');
    },
    onCancel() {
      this.$emit('onCancel');
    },
  },
});
</script>
