import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {getField, updateField} from 'vuex-map-fields';
import {DataService} from "@/dataService/dataService";
import {checkResponse} from "@/util/common-utils";
// import {notification} from "ant-design-vue";
// import {i18n} from "@/main";

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.ATTENDANCE_REPORT,
  todayTimeKeeping: {},
  totalStaff: 0,
  dataPieChart: [],
  dataLineChart: {
    xAxis: [],
    series: []
  },
  listLated: [],
  listExitEarly: [],
  barChartLated: {
    xAxis: [],
    series: [],
  },
  barChartEarly: {
    xAxis: [],
    series: []
  }
});

const actions = {
  async getTodayTimeKeeping({state}, params) {
    const response = await DataService.callApi(state.api.GET_TODAY_TIMEKEEPING, null, params);
    checkResponse(response, () => {
      state.todayTimeKeeping = response.data;
    });
  },
  async getUnitStaff({state, commit}, params) {
    const response = await DataService.callApi(state.api.GET_UNIT_STAFF, null, params);
    checkResponse(response, () => {
      commit('setPieChart', response.data.unitStaff);
      commit('setLineChart', response.data.resultStaffFluctuation.data);
    });
  },
  async getUnitTimeKeeping({state, commit}, params) {
    const response = await DataService.callApi(state.api.GET_UNIT_TIMEKEEPING, null, params);
    checkResponse(response, () => {
      commit('setListTable', response.data);
      commit('setBarChartLated', response.data);
      commit('setBarChartEarly', response.data);
    });
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
