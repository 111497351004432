import { flatten } from '@/util/common-utils';
import Cookies from 'js-cookie';

export default {
  loginBegin(state) {
    state.loading = true;
    state.error = null;
  },
  set2fa(state) {
    state.view2fa = true;
  },
  setTokenInfo(state, tokenInfo) {
    state.tokenInfo = tokenInfo;
    Cookies.set('tokenInfo', JSON.stringify(tokenInfo));
  },
  getUserInfoSuccess(state, data) {
    data.allMenu = flatten(data.menus || [], 'path');
    localStorage.setItem('allMenu', JSON.stringify(data.allMenu || []));
    state.userInfo = data;
    state.error = null;
    state.view2fa = false;
    state.loading = false;
  },
  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  logoutSuccess(state) {
    state.loading = false;
    state.tokenInfo = null;
    state.userInfo = null;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  routerLoaded(state) {
    state.routerLoaded = true;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  setUpLoading(state, loading) {
    state.uploading = loading;
  },

  setSocketId(state, socketId) {
    state.socketId = socketId;
  },
  setContinue(state, data) {
    state.isContinue = data;
  },
  setNotifications(state, data) {
    state.notifications.push(...data);
  },
  markReadNoti(state, id) {
    state.notifications.map((el) => {
      if (el.id === id) {
        el.seen = 1;
      }
    });
  },
};
